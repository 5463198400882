import React, { useEffect, useMemo, useState } from "react";
import styles from "./results.module.css";
import { QuestionResult } from "../../../models/game-result.entity";
import { useApiClient } from "../../../../providers/api-client";
import Button from "../../../components/Button/Button";
import cupLogo from "../../../../assets/images/cup-silver-gold-300.svg";
import { ReactComponent as Valid } from "../../../../assets/images/valid.svg";
import { ReactComponent as Invalid } from "../../../../assets/images/invalid.svg";

import { toast } from "react-toastify";
import { useStyles } from "../../../../providers/styles/use-styles";

interface Props {
  token: string;
  integrationId: string;
}

const Results = ({ token, integrationId }: Props) => {
  const client = useApiClient();
  const { getStyles } = useStyles();

  const [results, setResults] = useState<QuestionResult[]>();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    client
      .getResults(token, integrationId)
      .then((result) => {
        setResults(result.results);
      })
      .catch((error: any) => {
        console.log(error);

        toast.error("Не удалось выполнить запрос. Попробуйте еще раз.", {
          position: "top-center",
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      });
  }, []);

  const correctResults = useMemo(() => {
    return results?.filter((r) => r.answer.is_correct).length ?? 0;
  }, [results]);

  return (
    <div className={styles.wrapper}>
      <h3
        className={styles.title}
        style={getStyles(["txt_label_primary_base"])}
      >
        Результаты
      </h3>

      {!showDetails ? (
        <>
          <img
            className={styles.image}
            src={cupLogo}
            width={100}
            height={100}
            alt="лого"
          />
          <p
            className={styles.description}
            style={getStyles(["txt_label_secondary_base"])}
          >
            Ваш результат
          </p>
          {results ? (
            <p
              className={styles.result}
              style={getStyles(["txt_label_primary_base"])}
            >
              <span style={getStyles(["txt_label_primary_success"])}>
                {correctResults}
              </span>{" "}
              / {results.length}
            </p>
          ) : (
            <img
              className={styles.spinner}
              src="/images/circular-indeterminate-thin.svg"
              width={48}
              height={48}
              alt="загрузка"
            />
          )}

          <div className={styles.spacer} />

          {results && (
            <Button title="ДЕТАЛИ" onClick={() => setShowDetails(true)} />
          )}
        </>
      ) : (
        <div className={styles.answers}>
          {results?.map((result, position) => (
            <div className={styles.answer}>
              <span
                className={styles.answerPosition}
                style={getStyles(["txt_cell_primary_base"])}
              >
                {position + 1}
              </span>
              <span
                className={styles.answerText}
                style={getStyles(["txt_cell_primary_base"])}
              >
                {result.question.text}
              </span>

              <div
                className={styles.answerImage}
                style={getStyles([
                  result.answer.is_correct
                    ? "bg_icon_primary_success"
                    : "bg_icon_primary_error",
                ])}
              />

              <div
                className={styles.answerIcon}
                style={getStyles(["txt_label_primary_base"])}
              >
                {result.answer.is_correct ? <Valid /> : <Invalid />}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Results;
